<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="85px">
			<el-form-item class="el_form_item" label="主用户手机">
				<el-input class="el_input" v-model="form.main_user_tel" placeholder="主账号手机号码" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="主用户姓名">
				<el-input class="el_input" v-model="form.main_user_name" placeholder="主账号姓名" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="主用户公司" style="width: 400px;">
				<el-input class="el_input" v-model="form.main_user_company_name" placeholder="主账号公司名称" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">查询</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="主账户公司名称">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.main_user_company_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="主账户注册人">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.main_user_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="主账户手机号码">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.main_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="子账户类型">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="子账户余额">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.price}} 元</div>
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="107px">
					<template slot-scope="scope">
						<el-button class="btn_left" v-if="scope.row.status==1" @click="login_to_main_user(scope.row)" size="mini" type="text">登陆</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
</template>

<script>
	import {mapMutations} from 'vuex'
	export default {
		data() {
			return {

				//搜索条件
				form: {
					main_user_tel:'',//主用户手机
					main_user_name:'',//主用户姓名
					main_user_company_name:'',//主账号公司名称
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
			}
		},
		created() {
			
			//读取数据
			this.get_page_data()
		},
		methods: {
			
			...mapMutations(['save_main_user_info']),

			//登陆到主账户
			login_to_main_user(main_user){
				
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'sub_user_login_to_main_user',
						sub_user_id:main_user.id,
					},
					callback:(data)=>{

						//缓存主公司数据
						this.save_main_user_info(data);
						
						//登陆成功,跳转到主账号系统
						this.$router.push('/pages/sub_user/main_user_sys');
					}
				});
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					main_user_tel:'',//主用户手机
					main_user_name:'',//主用户姓名
					main_user_company_name:'',//主账号公司名称
				}
				this.get_page_data()
			},
			
			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'main_user_list_by_sub_user',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//状态
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}
							
							//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
							switch(item.type){
								case '1':item.type_text="全数据";break;
								case '2':item.type_text="部分数据";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 18%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>